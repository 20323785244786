import { FileDownloadListProps } from "./fileDownloadListProps";
import { Grid } from "@amzn/awsui-components-react";
import { FileDownload } from "../fileDownload/fileDownload";

export function FileDownloadList(props: FileDownloadListProps): JSX.Element {
    const rows = [];
    const fileDownloads = [];

    for (const file of props.files) {
        rows.push({ colspan: 12 });
        fileDownloads.push(<FileDownload key={file.downloadName} {...file}/>);
    }

    return (
        <Grid gridDefinition={rows}>
            {fileDownloads}
        </Grid>
    );
}