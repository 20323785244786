import {TrainingMetricsDashboardSettingsProps} from "./trainingMetricsDashboardSettingsProps";
import {createRef, RefObject, useEffect, useState} from "react";
import {TrainingMetricsDashboardSettingsState} from "./trainingMetricsDashboardSettingsState";
// @ts-ignore
import {embedDashboard} from "amazon-quicksight-embedding-sdk";
import {Spinner} from "@amzn/awsui-components-react";

export function TrainingMetricsDashboardSettings(props: TrainingMetricsDashboardSettingsProps): JSX.Element {
    const trainingMetricsDashboardViewModel = props.trainingMetricsDashboardViewModel;
    const batteryDashboardContainerRef = createRef<HTMLDivElement>();
    const batteryCountDashboardContainerRef = createRef<HTMLDivElement>();
    const weeeDashboardContainerRef = createRef<HTMLDivElement>();
    const [state, setState] = useState<TrainingMetricsDashboardSettingsState>({
        loading: true,
        dashboardUrls: { batteryDashboardEmbeddingUrl: "", weeeDashboardEmbeddingUrl: "", batteryCountDashboardEmbeddingUrl: "" }
    });

    function createDashboard(url: string, container:  RefObject<HTMLDivElement>) {
        if (!container.current || !url) {
            return;
        }

        const dashboardOptions = {
            url: url,
            container: container.current,
            scrolling: "no",
            height: "AutoFit",
            loadingHeight: "400px",
            width: "100%",
            locale: "en-US",
            printEnabled: true,
            undoRedoDisabled: false,
            resetDisabled: false,
            sheetTabsDisabled: false,
            qSearchBarOptions: {
                iconDisabled: true
            }
        };

        const dashboard = embedDashboard(dashboardOptions);

        dashboard.on("SHOW_MODAL_EVENT", showModalEventHandler);
    }

    const showModalEventHandler = () =>  {
        window.scrollTo({
            top: 0
        });
    };

    useEffect(() => {
        let mounted = true;

        trainingMetricsDashboardViewModel.getTrainingMetricsDashboardSettings()
            .then(urls => {
                if (mounted) {
                    setState({loading: false, dashboardUrls: urls})
                }
            });

        return () => { mounted = false };
    }, []);

    useEffect(() => {
        createDashboard(state.dashboardUrls.batteryDashboardEmbeddingUrl, batteryDashboardContainerRef);
        createDashboard(state.dashboardUrls.batteryCountDashboardEmbeddingUrl, batteryCountDashboardContainerRef);
        createDashboard(state.dashboardUrls.weeeDashboardEmbeddingUrl, weeeDashboardContainerRef);

    }, [batteryCountDashboardContainerRef, weeeDashboardContainerRef, batteryDashboardContainerRef]);

    if (state.loading) {
        return (
            <Spinner size="large" />
        );
    }

    return (
        <div>
            <div ref={batteryDashboardContainerRef}/>,
            <div ref={weeeDashboardContainerRef}/>
            <div ref={batteryCountDashboardContainerRef}/>
        </div>
    );
}
