import { Button } from "@amzn/awsui-components-react";
import { FileUploadProps } from "./fileUploadProps";
import { ChangeEvent, useState } from "react";
import "./fileUpload.scss"

export function FileUpload(props: FileUploadProps): JSX.Element {
    const [selectedFile, selectFile] = useState("");

    return (
        <div>
            <Button iconName="upload" className="file-upload-button">
                <input
                    className="file-input"
                    type="file"
                    accept={props.accept}
                    onChange={(event: ChangeEvent<HTMLInputElement>)=> {
                        if (!event.target.files || event.target.files.length == 0) {
                            return;
                        }
                        const file = event.target.files[0];
                        if (props.displaySelectedFileName) {
                            selectFile(file.name);
                        }
                        props.fileUploadHandler(file);
                    }}
                />
                {props.label}
            </Button>
            <label className="file-name">{selectedFile}</label>
        </div>
    );
}