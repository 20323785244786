import { InferenceDetails } from "../../../api/inferences/inferenceDetails";
import { InferenceService } from "../../../api/inferences/inferenceService";
import { WorkflowType } from "../../../common/workflowType";
import { SystemType } from "../../../common/systemType";

export class InferenceResultsViewModel {
    private readonly _systemType: SystemType;
    private readonly _inference: InferenceDetails;
    private readonly _inferenceService: InferenceService;

    constructor(systemType: SystemType, inference: InferenceDetails, inferenceService: InferenceService) {
        this._systemType = systemType;
        this._inference = inference;
        this._inferenceService = inferenceService;
    }

    public get systemType(): SystemType {
        return this._systemType;
    }

    public get inference(): InferenceDetails {
        return this._inference;
    }

    public getInferenceResultFileDownloadUrl(workflowType: WorkflowType, resultType: string): Promise<string> {
        return this._inferenceService.getInferenceResultFileDownloadUrl(this._inference.inferenceId, workflowType, resultType);
    }

    public getAuxiliaryFileDownloadUrl(workflowType: WorkflowType, fileName: string): Promise<string> {
        return this._inferenceService.getAuxiliaryFileDownloadUrl(this._inference.inferenceId, workflowType, fileName);
    }

    public getSampleFileDownloadUrl(sampleDownloadName: string): Promise<string> {
        return this._inferenceService.getSampleFileDownloadUrl(this._inference.inferenceId, sampleDownloadName);
    }

    public showAuxiliaryFileDownloadUrl(): boolean {
        if (this._systemType == SystemType.EPR_RETAIL) {
            return !this._inference.inferenceType.startsWith("adhoc");
        }
        return true;
    }
}