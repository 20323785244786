import {ApiClient} from "../apiClient";
import {TrainingDataSettings} from "./trainingDataSettings";
import {WorkflowType} from "../../common/workflowType";

export class TrainingDataService {
    private readonly _apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this._apiClient = apiClient;
    }

    public getTrainingDataSettings(): Promise<TrainingDataSettings> {
        return this._apiClient.get("/training");
    }

    public getTrainingDataFileDownloadUrl(workfklowType: WorkflowType, fileName: string): Promise<string> {
        return this._apiClient.get(`/training/download/${workfklowType}/${fileName}`);
    }
}