import {TrainingDataSettingsProps} from "./trainingDataSettingsProps";
import {useEffect, useState} from "react";
import {TrainingDataSettingsState} from "./trainingDataSettingsState";
import {Spinner, Tabs} from "@amzn/awsui-components-react";
import {WorkflowTrainingDataSettingsProps} from "./workflowTrainingSettings/workflowTrainingDataSettingsProps";
import {WorkflowType} from "../../common/workflowType";
import {WorkflowTrainingDataSettings} from "./workflowTrainingSettings/workflowTrainingDataSettings";
import {SystemType} from "../../common/systemType";

export function TrainingDataSettings(props: TrainingDataSettingsProps): JSX.Element {
    const trainingDataSettingsViewModel = props.trainingDataSettingsViewModel;
    const [state, setState] = useState<TrainingDataSettingsState>({loading: true, settings: null});

    useEffect(() => {
        let mounted = true;
        trainingDataSettingsViewModel.getTrainingDataSettings()
            .then(settings => {
               if (mounted) {
                   setState({loading: false, settings: settings})
               }
            });
        return () => {mounted = false};
    }, []);

    if (state.loading) {
        return (
            <Spinner size="large" />
        );
    }

    const listOfTabs = [];


    const batteryClassificationTrainingDataSettingsProps: WorkflowTrainingDataSettingsProps = {
        workflowTrainingDataSettingsViewModel: trainingDataSettingsViewModel.createWorkflowTrainingDataSettingsViewModel(WorkflowType.BATTERY, state.settings!.batteryClassificationTrainingSettings)
    };

        listOfTabs.push(
            {
                label: "Battery Classification Training Data",
                id: "battery",
                content: (
                    <WorkflowTrainingDataSettings {...batteryClassificationTrainingDataSettingsProps}/>
                )
            }
        )

    const weeeClassificationTrainingDataSettingsProps: WorkflowTrainingDataSettingsProps = {
        workflowTrainingDataSettingsViewModel: trainingDataSettingsViewModel.createWorkflowTrainingDataSettingsViewModel(WorkflowType.WEEE, state.settings!.weeeClassificationTrainingSettings)
    };

        listOfTabs.push(
            {
                label: "Weee Classification Training Data",
                id: "weee",
                content: (
                    <WorkflowTrainingDataSettings {...weeeClassificationTrainingDataSettingsProps}/>
                )
            }
        )

    if(trainingDataSettingsViewModel.getSystemType() != SystemType.EPR_THIRD_PARTY &&
        state.settings!.batteryCountTrainingSettings) {

        const batteryCountTrainingDataSettingsProps: WorkflowTrainingDataSettingsProps = {
            workflowTrainingDataSettingsViewModel: trainingDataSettingsViewModel.createWorkflowTrainingDataSettingsViewModel(
                WorkflowType.BATTERY_COUNT, state.settings!.batteryCountTrainingSettings
            )
        };

        listOfTabs.push(
            {
                label: "Battery Count Training Data",
                id: "battery_count",
                content: (
                    <WorkflowTrainingDataSettings {...batteryCountTrainingDataSettingsProps}/>
                )
            })
    }


    return (<Tabs tabs={listOfTabs} variant="container"/>);
}