import { WorkflowType } from "../../../common/workflowType";
import { SystemSettingsService } from "../../../api/system/systemSettingsService";
import { WorkflowMappingSettings } from "../../../api/system/systemMappingSettings";
import { MappingFileViewModel } from "../mappingFile/mappingFileViewModel";
import { MappingFilesUploaderViewModel } from "../mappingFilesUploader/mappingFilesUploaderViewModel";

export class WorkflowMappingSettingsViewModel {
    private readonly _systemSettingsService: SystemSettingsService;
    private readonly _workflowType: WorkflowType;
    private readonly _workflowMappingSettings: WorkflowMappingSettings;

    constructor(
        systemSettingsService: SystemSettingsService,
        workflowType: WorkflowType,
        workflowMappingSettings: WorkflowMappingSettings) {
        this._systemSettingsService = systemSettingsService;
        this._workflowType = workflowType;
        this._workflowMappingSettings = workflowMappingSettings;
    }

    public createLabelDefinitionsFileViewModel(): MappingFileViewModel {
        return new MappingFileViewModel(
            this._systemSettingsService, this._workflowType, this._workflowMappingSettings.labelDefinitionsFile);
    }

    public createMappingRulesFileViewModel(): MappingFileViewModel {
        return new MappingFileViewModel(
            this._systemSettingsService, this._workflowType, this._workflowMappingSettings.mappingRulesFile);
    }

    public createMappingFilesUploaderViewModel(): MappingFilesUploaderViewModel {
        return new MappingFilesUploaderViewModel(
            this._systemSettingsService,
            this._workflowType,
            this._workflowMappingSettings.labelDefinitionsFile.downloadName,
            this._workflowMappingSettings.mappingRulesFile.downloadName);
    }
}