import { InferenceService } from "../../../api/inferences/inferenceService";
import { AuditFile } from "../../../api/inferences/inferenceDetails";
import { ApiError } from "../../../api/apiError";
import { eventBus } from "../../../common/events/eventBus";
import { fileUploadErrorOccurred } from "../../../common/events/events";
import { ErrorMessages } from "../../../common/errorMessages";

export class InferenceAuditViewModel {
    private readonly _inferenceId: string;
    private readonly _auditFiles: AuditFile[];
    private readonly _inferenceService: InferenceService;

    constructor(inferenceId: string, auditFiles: AuditFile[], inferenceService: InferenceService) {
        this._inferenceId = inferenceId;
        this._auditFiles = auditFiles;
        this._inferenceService = inferenceService;
    }

    public async uploadInferenceAuditFile(auditFileName: string, file: File): Promise<void> {
        try {
            const uploadUrl = await this._inferenceService.getInferenceAuditFileUploadUrl(this._inferenceId, auditFileName);

            const response = await fetch(uploadUrl, {method: "PUT", body: file});
            if (!response.ok) {
                throw new ApiError(response.status);
            }
        }
        catch (error: any) {
            eventBus.publish(fileUploadErrorOccurred({ message: ErrorMessages.FILE_UPLOAD_ERROR }));
            return Promise.reject(error);
        }
    }

    public async downloadInferenceAuditFile(auditFileName: string): Promise<string> {
        return this._inferenceService.getInferenceAuditFileDownloadUrl(this._inferenceId, auditFileName);
    }

    public getAuditFileUpdatedDate(fileName: string): number {
        const uploadedFile = this._auditFiles.find(auditFile => auditFile.downloadName === fileName);
        if (!uploadedFile) {
            return 0;
        }
        return uploadedFile.updatedDate;
    }
}