import { FileDownloadProps } from "./fileDownloadProps";
import { Icon, Link, SpaceBetween } from "@amzn/awsui-components-react";
import { saveAs } from "file-saver";

export function FileDownload(props: FileDownloadProps) : JSX.Element {
    return (
        <SpaceBetween direction="horizontal" size="xs">
            <Icon name="download"/>
            <Link
                href="#"
                onFollow={(event: Event) => {
                    event.preventDefault();
                    props.getFileDownloadUrl()
                        .then(url => saveAs(url, props.downloadName))
                }}
            >
                {props.displayName}
            </Link>
        </SpaceBetween>
    );
}