import { AppLayout, SideNavigation } from "@amzn/awsui-components-react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useState } from "react";
import { InferenceList } from "../../components/inferenceList/inferenceList";
import { AppViewProps } from "./viewProps";
import { InferenceMetricsDashboard } from "../../components/inferenceMetricsDashboard/inferenceMetricsDashboard";
import { SystemMappingSettings } from "../../components/systemMappingSettings/systemMappingSettings";
import { Inference } from "../../components/inference/inference";
import { Notification } from "./notification/notification";
import {TrainingDataSettings} from "../../components/trainingDataSettings/trainingDataSettings";
import {
    TrainingMetricsDashboardSettings
} from "../../components/trainingMetricsDashboard/trainingMetricsDashboardSettings";

export function AppView(props: AppViewProps): JSX.Element {
    const initialPage = window.location.hash ? window.location.hash : "#/inferences";
    const [activePage, setActivePage] = useState(initialPage);

    return (
        <AppLayout
            headerSelector="#header"
            toolsHide={true}
            navigation={
                <SideNavigation
                    activeHref={activePage}
                    onFollow={event => {
                        if (!event.detail.external) {
                            setActivePage(event.detail.href);
                        }
                    }}
                    items={
                    [
                        {
                            type: "section",
                            text: "Inference",
                            items: [
                                { type: "link", text: "Inferences", href: "#/inferences" },
                                { type: "link", text: "Metrics Dashboard", href: "#/inferences/metrics-dashboard" },
                            ]
                        },
                        { type: "divider" },
                        {
                            type: "section",
                            text: "Training",
                            items: [
                                { type: "link", text: "Training Data", href: "#/training" },
                                { type: "link", text: "Metrics Dashboard", href: "#/training/metrics-dashboard"}
                            ]
                        },
                        { type: "divider" },
                        {
                            type: "section",
                            text: "Administration",
                            items: [
                                { type: "link", text: "Mapping", href: "#/administration/mapping" },
                            ]
                        },
                    ]
                }
                />
            }
            stickyNotifications={true}
            notifications={
                <Notification />
            }
            content={
                <Routes>
                    <Route path="/" element={<Navigate to="/inferences" />} />
                    <Route path="/inferences" element={<InferenceList {...props.inferenceListProps}/>}/>
                    <Route path="/inferences/:inferenceId" element={<Inference {...props.inferenceProps}/>}/>
                    <Route path="/inferences/metrics-dashboard" element={<InferenceMetricsDashboard {...props.inferenceMetricsDashboardProps}/>}/>
                    <Route path="/administration/mapping" element={<SystemMappingSettings {...props.systemMappingSettingsProps}/>}/>
                    <Route path="/training" element={<TrainingDataSettings {...props.trainingDataSettingsProps}/>}/>
                    <Route path="/training/metrics-dashboard" element={<TrainingMetricsDashboardSettings {...props.trainingMetricsDashboardSettingsProps}/>}/>
                </Routes>
            }
        >
        </AppLayout>
    );
}