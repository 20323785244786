import {WorkflowTrainingDataSettingsProps} from "./workflowTrainingDataSettingsProps";
import {TrainingDataFileProps} from "../trainingDataFile/trainingDataFileProps";
import {Grid} from "@amzn/awsui-components-react";
import {TrainingDataFile} from "../trainingDataFile/trainingDataFile";

export function WorkflowTrainingDataSettings(props: WorkflowTrainingDataSettingsProps): JSX.Element {
    const workflowTrainingDataSettingsViewModel = props.workflowTrainingDataSettingsViewModel;

    const trainDataFileProps: TrainingDataFileProps = {
        trainingDataFileViewModel: workflowTrainingDataSettingsViewModel.createTrainDataFileViewModel()
    };

    const validationDataFileProps: TrainingDataFileProps = {
        trainingDataFileViewModel: workflowTrainingDataSettingsViewModel.createValidationDataFileViewModel()
    };

    const testDataFileProps: TrainingDataFileProps = {
        trainingDataFileViewModel: workflowTrainingDataSettingsViewModel.createTestDataFileViewModel()
    };

    return (
        <Grid
            gridDefinition={
                [
                    {colspan: 12},
                    {colspan: 12},
                    {colspan: 12}
                ]
            }
        >
            <TrainingDataFile {...trainDataFileProps}/>
            <TrainingDataFile {...validationDataFileProps}/>
            <TrainingDataFile {...testDataFileProps}/>
        </Grid>
    );
}