import { InferenceDetails } from "../../../api/inferences/inferenceDetails";
import { InferenceService } from "../../../api/inferences/inferenceService";

export class InferenceInputSampleViewModel {
    private readonly _inference: InferenceDetails;
    private readonly  _inferenceService: InferenceService;

    constructor(inference: InferenceDetails, inferenceService: InferenceService) {
        this._inference = inference;
        this._inferenceService = inferenceService;
    }

    public getInferenceInputSampleFileDownloadUrl(): Promise<string> {
        return this._inferenceService.getInferenceInputSampleFileDownloadUrl(this._inference.inferenceId);
    }
}