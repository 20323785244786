import { SystemMappingSettingsProps } from "./systemMappingSettingsProps";
import { useEffect, useState } from "react";
import { SystemMappingSettingsState } from "./systemMappingSettingsState";
import { Spinner, Tabs } from "@amzn/awsui-components-react";
import { WorkflowMappingSettings } from "./workflowMappingSettings/workflowMappingSettings";
import { WorkflowType } from "../../common/workflowType";
import { WorkflowMappingSettingsProps } from "./workflowMappingSettings/workflowMappingSettingsProps";

export function SystemMappingSettings(props: SystemMappingSettingsProps): JSX.Element {
    const systemMappingSettingsViewModel = props.systemMappingSettingsViewModel;
    const [state, setState] = useState<SystemMappingSettingsState>({ loading: true, settings: null });

    useEffect(() => {
        let mounted = true;
        systemMappingSettingsViewModel.getMappingSettings()
            .then(settings => {
                if (mounted) {
                    setState({loading: false, settings: settings})
                }
            });
        return () => { mounted = false };
    }, []);

    if (state.loading) {
        return (
            <Spinner size="large" />
        );
    }

    const batteryMappingSettingsProps: WorkflowMappingSettingsProps = {
        workflowMappingSettingsViewModel: systemMappingSettingsViewModel
            .createWorkflowMappingSettingsViewModel(WorkflowType.BATTERY, state.settings!.batteryMappingSettings)
    };

    const weeeMappingSettingsProps: WorkflowMappingSettingsProps = {
        workflowMappingSettingsViewModel: systemMappingSettingsViewModel
            .createWorkflowMappingSettingsViewModel(WorkflowType.WEEE, state.settings!.weeeMappingSettings)
    };

    return (
        <Tabs
            tabs={[
                {
                    label: "Battery Mapping",
                    id: "battery",
                    content: (
                        <WorkflowMappingSettings {...batteryMappingSettingsProps}/>
                    )
                },
                {
                    label: "Weee Mapping",
                    id: "weee",
                    content: (
                        <WorkflowMappingSettings {...weeeMappingSettingsProps}/>
                    )
                }
            ]}
            variant="container"
        />
    );
}