import { MappingFile } from "../mappingFile/mappingFile";
import { Grid } from "@amzn/awsui-components-react";
import { WorkflowMappingSettingsProps } from "./workflowMappingSettingsProps";
import { MappingFileProps } from "../mappingFile/mappingFileProps";
import { MappingFilesUploader } from "../mappingFilesUploader/mappingFilesUploader";
import { MappingFilesUploaderProps } from "../mappingFilesUploader/mappingFilesUploaderProps";

export function WorkflowMappingSettings(props: WorkflowMappingSettingsProps): JSX.Element {
    const workflowMappingSettingsViewModel = props.workflowMappingSettingsViewModel;

    const labelDefinitionsFileProps: MappingFileProps = {
        mappingFileViewModel: workflowMappingSettingsViewModel.createLabelDefinitionsFileViewModel()
    };

    const mappingRulesFileProps: MappingFileProps = {
        mappingFileViewModel: workflowMappingSettingsViewModel.createMappingRulesFileViewModel()
    };

    const mappingFilesUploaderProps: MappingFilesUploaderProps = {
        mappingFilesUploaderViewModel: workflowMappingSettingsViewModel.createMappingFilesUploaderViewModel()
    }

    return (
        <Grid
            gridDefinition={
                [
                    {colspan: 12},
                    {colspan: 12},
                    {colspan: 12}
                ]
            }
        >
            <MappingFile {...labelDefinitionsFileProps}/>
            <MappingFile {...mappingRulesFileProps}/>
            <MappingFilesUploader {...mappingFilesUploaderProps}/>
        </Grid>
    );
}