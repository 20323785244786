import {TrainingDataFileProps} from "./trainingDataFileProps";
import {Grid} from "@amzn/awsui-components-react";
import {FileDownload} from "../../common/fileDownload/fileDownload";
import {DateFormatter} from "../../../common/dateFormatter";

export function TrainingDataFile(props: TrainingDataFileProps): JSX.Element {
    const trainingDataFileViewModel = props.trainingDataFileViewModel;
    const file = trainingDataFileViewModel.getFile();

    return (
        <Grid gridDefinition={[{colspan: 2}, {colspan: 10}]}>
            <FileDownload displayName={file.displayName} downloadName={file.downloadName} getFileDownloadUrl={() => trainingDataFileViewModel.getFileDownloadUrl()}/>
            <label>updated {DateFormatter.formatDateTime(file.updateDate)}</label>
        </Grid>
    )
}