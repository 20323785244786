import { Alert, Box, Button, DatePicker, Grid, Modal, Select, SelectProps, SpaceBetween } from "@amzn/awsui-components-react";
import { AdHocInferenceLauncherProps } from "./adHocInferenceLauncherProps";
import { useState } from "react";
import { SystemType } from "../../../common/systemType";
import { DateFormatter } from "../../../common/dateFormatter";
import { FileUpload } from "../../common/fileUpload/fileUpload";

export function AdHocInferenceLauncher(props: AdHocInferenceLauncherProps): JSX.Element {
    const inferenceTypes = props.systemType == SystemType.EPR_RETAIL
        ? [{ label: "agl", value: "agl" }, { label: "retail", value: "retail" }]
        : [{ label: "3p", value: "3p" }];

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const initialInferenceDate = new Date();
    initialInferenceDate.setDate(initialInferenceDate.getDate() - 1);

    const [selectedInferenceType, setSelectedInferenceType] = useState<SelectProps.Option>(inferenceTypes[0]);
    const [selectedInferenceDate, setSelectedInferenceDate] = useState(
        DateFormatter.formatDate(initialInferenceDate.getTime() / 1000)
    );

    const [selectedAsinListFile, setSelectedAsinListFile] = useState<File|null>(null);

    const [error, setError] = useState("");

    const [isUploadInProgress, setIsUploadInProgress] = useState(false);

    return (
        <Modal
            onDismiss={() => props.closeInferenceLauncher()}
            visible={props.isInferenceLauncherVisible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => props.closeInferenceLauncher()}>Cancel</Button>
                        <Button variant="primary"
                                loading={isUploadInProgress}
                                onClick={() => {
                                    if (!selectedAsinListFile) {
                                        setError("Please choose a file with ASINs list");
                                        return;
                                    }

                                    setIsUploadInProgress(true);
                                    props.launchAdhocInference(selectedInferenceType.value!, selectedInferenceDate, selectedAsinListFile)
                                        .then(() => props.closeInferenceLauncher())
                                        .catch(error => {
                                            if (error.status === 400) {
                                                setError("Failed to launch ad hoc inference. Try choosing a different date or reach out to the team");
                                                return Promise.resolve();
                                            }
                                            else {
                                                props.closeInferenceLauncher();
                                                return Promise.reject(error);
                                            }
                                        })
                                        .finally(() => setIsUploadInProgress(false));
                                }}>
                            Launch
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Launch Ad Hoc Inference"
        >
            <Grid
                gridDefinition={[
                    {colspan: 6}, {colspan: 6},
                    {colspan: 6}, {colspan: 6},
                    {colspan: 12},
                    {colspan: 12}
                ]}
            >
                <Select
                    selectedOption={selectedInferenceType}
                    onChange={(event: any) => setSelectedInferenceType(event.detail.selectedOption)}
                    options={inferenceTypes}
                    disabled={isUploadInProgress}
                />
                <div/>

                <DatePicker
                    value={selectedInferenceDate}
                    onChange={(event: any) => setSelectedInferenceDate(event.detail.value)}
                    todayAriaLabel="Today"
                    nextMonthAriaLabel="Next month"
                    previousMonthAriaLabel="Previous month"
                    disabled={isUploadInProgress}
                    isDateEnabled={(date: Date) => date < today}
                />
                <div/>

                <FileUpload
                    label="Upload ASINs List"
                    accept=".csv"
                    displaySelectedFileName={true}
                    fileUploadHandler={(file) => setSelectedAsinListFile(file)}
                />

                <Alert
                    onDismiss={() => setError("")}
                    visible={!!error}
                    dismissAriaLabel="Close alert"
                    dismissible
                    type="error"
                >
                    {error}
                </Alert>
            </Grid>
        </Modal>
    );
}