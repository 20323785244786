import axios, { Axios } from "axios";
import { UserManager } from "oidc-react";
import { eventBus } from "../common/events/eventBus";
import { serverErrorOccurred } from "../common/events/events";
import { ErrorMessages } from "../common/errorMessages";
import { ApiError } from "./apiError";

export class ApiClient {
    private readonly _apiClient: Axios;

    constructor(apiUri: string, userManager: UserManager) {
        const apiClient = axios.create({
            baseURL: apiUri,
            headers: {
                "Content-Type": "application/json",
            }
        });

        apiClient.interceptors.request.use(async config => {
            const user = await userManager.getUser();
            if (user && user.access_token) {
                config.headers!.Authorization = user.access_token;
            }
            return config;
        });

        apiClient.interceptors.response.use(
            response => response,
            error => {
                const apiError = this.handleError(error);
                return Promise.reject(apiError);
            }
        );

        this._apiClient = apiClient;
    }

    public get<T = any>(url: string): Promise<T> {
        return this._apiClient.get<T>(url).then(response => response.data);
    }

    public post<T = any, D = any>(url: string, data?: D, headers?: Record<string, string>): Promise<T> {
        return this._apiClient.post(url, data, {
            headers: headers
        }).then(response => response.data);
    }

    private handleError(error?: any): ApiError {
        const status = error?.response?.status;

        if (status === 403) {
            eventBus.publish(serverErrorOccurred({ message: ErrorMessages.FORBIDDEN_ERROR }));
        }
        else if (status == 500) {
            eventBus.publish(serverErrorOccurred({ message: ErrorMessages.INTERNAL_SERVER_ERROR }));
        }

        return new ApiError(status);
    }
}

