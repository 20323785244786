import { UserManager } from "oidc-react";
import { Configuration } from "../configuration/configuration";
import { ConfigurationService } from "../configuration/configurationService";
import { ApiClient } from "../api/apiClient";
import { InferenceService } from "../api/inferences/inferenceService";
import { InferenceListViewModel } from "../components/inferenceList/inferenceListViewModel";
import { InferenceMetricsDashboardViewModel } from "../components/inferenceMetricsDashboard/inferenceMetricsDashboardViewModel";
import { InferenceMetricsDashboardService } from "../api/inferences/inferenceMetricsDashboardService";
import { SystemMappingSettingsViewModel } from "../components/systemMappingSettings/systemMappingSettingsViewModel";
import { SystemSettingsService } from "../api/system/systemSettingsService";
import { InferenceViewModel } from "../components/inference/inferenceViewModel";
import { TrainingDataSettingsViewModel } from "../components/trainingDataSettings/trainingDataSettingsViewModel";
import { TrainingDataService } from "../api/training/trainingDataService";
import { TrainingMetricsDashboardService } from "../api/training/trainingMetricsDashboardService";
import {
    TrainingMetricsDashboardSettingsViewModel
} from "../components/trainingMetricsDashboard/trainingMetricsDashboardSettingsViewModel";

export class AppViewModel {
    private _configuration: Configuration | null = null;
    private _userManager: UserManager | null = null;
    private _inferenceListViewModel: InferenceListViewModel | null = null;
    private _inferenceViewModel: InferenceViewModel | null = null;
    private _inferenceMetricsDashboardViewModel: InferenceMetricsDashboardViewModel | null = null;
    private _systemMappingSettingsViewModel: SystemMappingSettingsViewModel | null = null;
    private _trainingDataSettingsViewModel: TrainingDataSettingsViewModel | null = null;
    private _trainingMetricsDashboardSettingsViewModel: TrainingMetricsDashboardSettingsViewModel | null = null;

    public async initialize(): Promise<void> {
        const configurationService = new ConfigurationService();
        this._configuration = await configurationService.getConfiguration();

        this._userManager = this._createUserManager();

        const apiClient = new ApiClient(this._configuration!.apiUri, this._userManager);
        const inferenceService = new InferenceService(apiClient);
        const inferenceMetricsDashboardService = new InferenceMetricsDashboardService(apiClient);
        const systemSettingsService = new SystemSettingsService(apiClient);
        const trainingDataService = new TrainingDataService(apiClient);
        const trainingMetricsDashboardService = new TrainingMetricsDashboardService(apiClient);

        this._inferenceListViewModel = new InferenceListViewModel(this._configuration.systemType, inferenceService);
        this._inferenceViewModel = new InferenceViewModel(this._configuration.systemType, inferenceService);
        this._inferenceMetricsDashboardViewModel = new InferenceMetricsDashboardViewModel(inferenceMetricsDashboardService);
        this._systemMappingSettingsViewModel = new SystemMappingSettingsViewModel(systemSettingsService);
        this._trainingDataSettingsViewModel = new TrainingDataSettingsViewModel(this._configuration.systemType, trainingDataService);
        this._trainingMetricsDashboardSettingsViewModel = new TrainingMetricsDashboardSettingsViewModel(trainingMetricsDashboardService);

    }

    private _createUserManager(): UserManager {
        return new UserManager({
            authority: this._configuration!.authority,
            client_id: this._configuration!.clientId,
            redirect_uri: this._configuration!.redirectUri,
            silent_redirect_uri: this._configuration!.redirectUri,
            post_logout_redirect_uri: this._configuration!.redirectUri,
            response_type: "code",
            scope: `openid ${this._configuration!.apiUri} aws.cognito.signin.user.admin`,
            loadUserInfo: true,
            automaticSilentRenew: true
        });
    }

    public get userManager(): UserManager {
        return this._userManager!;
    }

    public get applicationName(): string {
        return `${this._configuration!.applicationNamePrefix} Inference Browser`;
    }

    public get inferenceListViewModel(): InferenceListViewModel {
        return this._inferenceListViewModel!;
    }

    public get inferenceViewModel(): InferenceViewModel {
        return this._inferenceViewModel!;
    }

    public get inferenceMetricsDashboardViewModel(): InferenceMetricsDashboardViewModel {
        return this._inferenceMetricsDashboardViewModel!;
    }

    public get systemMappingSettingsViewModel(): SystemMappingSettingsViewModel {
        return this._systemMappingSettingsViewModel!;
    }

    public get trainingDataSettingsViewModel(): TrainingDataSettingsViewModel {
        return this._trainingDataSettingsViewModel!;
    }

    public get trainingMetricsDashboardSettingsViewModel(): TrainingMetricsDashboardSettingsViewModel {
        return this._trainingMetricsDashboardSettingsViewModel!;
    }
}