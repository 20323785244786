import {ApiClient} from "../apiClient";
import {TrainingMetricsSettings} from "./trainingMetricsSettings";

export class TrainingMetricsDashboardService {
    private readonly _apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this._apiClient = apiClient;
    }

    public getDashboardsUrls(): Promise<TrainingMetricsSettings> {
        return this._apiClient.get("/training/metrics-dashboard");
    }
}