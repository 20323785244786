import { HashRouter } from "react-router-dom";
import { Spinner } from "@amzn/awsui-components-react";
import { useAuth } from "oidc-react";
import { AppHeader } from "./header/header";
import { AppView } from "./view/view";
import { AppProps } from "./appProps";
import { AppViewProps } from "./view/viewProps";
import { AppHeaderProps } from "./header/headerProps";

export function App(props: AppProps): JSX.Element {
    const appViewModel = props.appViewModel;

    document.title = appViewModel.applicationName;

    const auth = useAuth();
    if (!auth || auth.isLoading) {
        return (
            <Spinner size="large" />
        );
    }

    const userProfile = auth.userData!.profile;

    const headerProps: AppHeaderProps = {
        title: appViewModel.applicationName,
        userEmail: userProfile!.email!,
        userGivenName: userProfile!.given_name!,
        userFamilyName: userProfile!.family_name!
    };

    const viewProps: AppViewProps = {
        inferenceListProps: {
            inferenceListViewModel: appViewModel.inferenceListViewModel
        },
        inferenceProps: {
            inferenceViewModel: appViewModel.inferenceViewModel
        },
        inferenceMetricsDashboardProps: {
            inferenceMetricsDashboardViewModel: appViewModel.inferenceMetricsDashboardViewModel
        },
        systemMappingSettingsProps: {
            systemMappingSettingsViewModel: appViewModel.systemMappingSettingsViewModel
        },
        trainingDataSettingsProps: {
            trainingDataSettingsViewModel: appViewModel.trainingDataSettingsViewModel
        },
        trainingMetricsDashboardSettingsProps: {
            trainingMetricsDashboardViewModel: appViewModel.trainingMetricsDashboardSettingsViewModel
        }
    }

    return (
        <HashRouter>
            <AppHeader {...headerProps}/>
            <AppView {...viewProps}/>
        </HashRouter>
    );
}