import { useParams } from "react-router-dom";
import { InferenceProps } from "./inferenceProps";
import { useEffect, useState } from "react";
import { Grid, Header, Spinner } from "@amzn/awsui-components-react";
import { InferenceState } from "./inferenceState";
import { InferenceResults } from "./inferenceResults/inferenceResults";
import { InferenceAudit } from "./inferenceAudit/inferenceAudit";
import { InferenceInputSample } from "./inferenceInput/inferenceInputSample";

export function Inference(props: InferenceProps): JSX.Element {
    const params = useParams();
    const inferenceId = params.inferenceId!;
    const inferenceViewModel = props.inferenceViewModel;

    const [state, setState] = useState<InferenceState>({ loading: true });

    useEffect(() => {
        let mounted = true;
        inferenceViewModel.getInference(inferenceId)
            .then(inference => {
                if (mounted) {
                    setState({ loading: false, inference: inference })
                }
            });
        return () => { mounted = false };
    }, [inferenceId]);

    if (state.loading) {
        return (
            <Spinner size="large" />
        );
    }

    const inferenceInputSampleProps = {
      inferenceInputSampleViewModel: inferenceViewModel.createInferenceInputSampleFileViewModel(state.inference!)
    };

    const inferenceResultsProps = {
        inferenceResultsViewModel: inferenceViewModel.createInferenceResultsViewModel(state.inference!)
    };

    const inferenceAuditFilesProps = {
        inferenceAuditViewModel: inferenceViewModel.createInferenceAuditFileViewModel(state.inference!.inferenceId, state.inference!.auditFiles)
    };

    const showInferenceAuditElement = function(): JSX.Element {
       return state!.inference!.isAuditAvailable ? <InferenceAudit {...inferenceAuditFilesProps}/> : <div></div>
    }

    return (
            <Grid
                gridDefinition={[
                    { colspan: 12 },
                    { colspan: 12 },
                    { colspan: 12 },
                    { colspan: 12 }
                ]}
            >
                <Header variant="h1">{`${state.inference!.inferenceDate} ${state.inference!.inferenceType} Inference`}</Header>
                <InferenceInputSample {...inferenceInputSampleProps}/>
                <InferenceResults {...inferenceResultsProps}/>
                {showInferenceAuditElement()}
            </Grid>

    );
}