import { InferenceMetricsDashboardProps } from "./inferenceMetricsDashboardProps";
import { createRef, useEffect, useState } from "react";
import { InferenceMetricsDashboardState } from "./InferenceMetricsDashboardState";
import { Spinner } from "@amzn/awsui-components-react";
// @ts-ignore
import { embedDashboard } from "amazon-quicksight-embedding-sdk";

export function InferenceMetricsDashboard(props: InferenceMetricsDashboardProps): JSX.Element {
    const inferenceMetricsDashboardViewModel = props.inferenceMetricsDashboardViewModel;
    const dashboardContainerRef = createRef<HTMLDivElement>();
    const [state, setState] = useState<InferenceMetricsDashboardState>({ loading: true, dashboardUrl: "" });

    useEffect(() => {
        let mounted = true;

        inferenceMetricsDashboardViewModel.getMetricsDashboardUrl()
            .then(url => {
                if (mounted) {
                    setState({loading: false, dashboardUrl: url})
                }
            });

        return () => { mounted = false };
    },
    []);

    useEffect(() => {
        if (!dashboardContainerRef.current || !state.dashboardUrl) {
            return;
        }

        // https://www.npmjs.com/package/amazon-quicksight-embedding-sdk
        const options = {
            url: state.dashboardUrl,
            container: dashboardContainerRef.current,
            scrolling: "no",
            height: "AutoFit",
            loadingHeight: "800px",
            width: "100%",
            locale: "en-US",
            printEnabled: true,
            undoRedoDisabled: false,
            resetDisabled: false,
            sheetTabsDisabled: false,
            qSearchBarOptions: {
                iconDisabled: true
            }
        }

        const dashboard = embedDashboard(options);
        dashboard.on("SHOW_MODAL_EVENT", () => {
            window.scrollTo({
                top: 0
            });
        });
    },
    [dashboardContainerRef]);

    if (state.loading) {
        return (
            <Spinner size="large" />
        );
    }

    return (
        <div ref={dashboardContainerRef}></div>
    );
}