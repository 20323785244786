import {TrainingDataService} from "../../../api/training/trainingDataService";
import {WorkflowType} from "../../../common/workflowType";
import {WorkflowTrainingDataSettings} from "../../../api/training/trainingDataSettings";
import {TrainingDataFileViewModel} from "../trainingDataFile/trainingDataFileViewModel";

export class WorkflowTrainingDataSettingsViewModel {
    private readonly _trainingDataService: TrainingDataService;
    private readonly _workflowType: WorkflowType;
    private readonly _workflowTrainingDataSettings: WorkflowTrainingDataSettings;

    constructor(
        trainingDataService: TrainingDataService,
        workflowType: WorkflowType,
        workflowTrainingDataSettings: WorkflowTrainingDataSettings) {
        this._trainingDataService = trainingDataService;
        this._workflowType = workflowType;
        this._workflowTrainingDataSettings = workflowTrainingDataSettings;
    }

    public createTrainDataFileViewModel(): TrainingDataFileViewModel {
        return new TrainingDataFileViewModel(
            this._trainingDataService, this._workflowType, this._workflowTrainingDataSettings.trainDataFile);
    }

    public createValidationDataFileViewModel(): TrainingDataFileViewModel {
        return new TrainingDataFileViewModel(
            this._trainingDataService, this._workflowType, this._workflowTrainingDataSettings.validationDataFile);
    }

    public createTestDataFileViewModel(): TrainingDataFileViewModel {
        return new TrainingDataFileViewModel(
            this._trainingDataService, this._workflowType, this._workflowTrainingDataSettings.testDataFile);
    }
}