import { InferenceService } from "../../api/inferences/inferenceService";
import { InferenceItem } from "./inferenceItem";
import { SystemType } from "../../common/systemType";
import { ApiError } from "../../api/apiError";
import { eventBus } from "../../common/events/eventBus";
import { fileUploadErrorOccurred } from "../../common/events/events";
import { ErrorMessages } from "../../common/errorMessages";

export class InferenceListViewModel {
    private readonly _systemType: SystemType;
    private readonly _inferenceService: InferenceService;

    constructor(systemType: SystemType, inferenceService: InferenceService) {
        this._systemType = systemType;
        this._inferenceService = inferenceService;
    }

    public get systemType(): SystemType {
        return this._systemType;
    }

    public async getInferences(): Promise<InferenceItem[]> {
        const inferences = await this._inferenceService.getInferences();
        return inferences.sort((a, b) => b.executionTime - a.executionTime)
                        .map(i => new InferenceItem(i))
    }

    public async uploadAsinListFile(inferenceType: string, inferenceDate: string, asinListFile: File): Promise<void> {
        try {
            const uploadUrl = await this._inferenceService.getAdHocInferenceAsinsListUploadUrl(inferenceType, inferenceDate, asinListFile.name);

            const response = await fetch(uploadUrl, {method: "PUT", body: asinListFile});
            if (!response.ok) {
                throw new ApiError(response.status);
            }
        }
        catch (error: any) {
            eventBus.publish(fileUploadErrorOccurred({ message: ErrorMessages.FILE_UPLOAD_ERROR }));
            return Promise.reject(error);
        }
    }

    public launchAdhocInference(inferenceType: string, inferenceDate: string): Promise<void> {
        return this._inferenceService.launchAdHocInference(inferenceType, inferenceDate);
    }
}