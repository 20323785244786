import { SystemSettingsService } from "../../../api/system/systemSettingsService";
import { WorkflowType } from "../../../common/workflowType";
import { eventBus } from "../../../common/events/eventBus";
import { fileUploadErrorOccurred } from "../../../common/events/events";
import { ErrorMessages } from "../../../common/errorMessages";
import { ApiError } from "../../../api/apiError";

export class MappingFilesUploaderViewModel {
    private readonly _systemSettingsService: SystemSettingsService;
    private readonly _workflowType: WorkflowType;
    private readonly _labelDefinitionsFileUploadName: string;
    private readonly _mappingRulesFileUploadName: string;

    private _labelDefinitionsFile: File|null = null;
    private _mappingRulesFile: File|null = null;

    constructor(
        systemSettingsService: SystemSettingsService,
        workflowType: WorkflowType,
        labelDefinitionsFileUploadName: string,
        mappingRulesFileUploadName: string) {
        this._systemSettingsService = systemSettingsService;
        this._workflowType = workflowType;
        this._labelDefinitionsFileUploadName = labelDefinitionsFileUploadName;
        this._mappingRulesFileUploadName = mappingRulesFileUploadName;
    }

    public handleLabelDefinitionsFileUpload(file: File): void {
        this._labelDefinitionsFile = file;
    }

    public handleMappingRulesFileUpload(file: File): void {
        this._mappingRulesFile = file;
    }

    public get isValid(): boolean {
        return !!this._labelDefinitionsFile && !!this._mappingRulesFile;
    }

    public async uploadMappingFiles(): Promise<string> {
        if (!this.isValid) {
            throw new Error("Both Label Definitions and Mapping Rules files must be chosen");
        }

        try {
            await Promise
                .all([
                    this._uploadMappingFile(this._workflowType, this._labelDefinitionsFileUploadName, this._labelDefinitionsFile!),
                    this._uploadMappingFile(this._workflowType, this._mappingRulesFileUploadName, this._mappingRulesFile!)
                ]);

            return "New mapping files were successfully uploaded. " +
                "Validation process will start shortly and the team will be notified with the results to perform a migration to the new files";
        }
        catch (error: any) {
            eventBus.publish(fileUploadErrorOccurred({ message: ErrorMessages.FILE_UPLOAD_ERROR }));
            return Promise.reject(error);
        }
    }

    private async _uploadMappingFile(workflowType: WorkflowType, fileUploadName: string, file: File): Promise<void> {
        const uploadUrl = await this._systemSettingsService.getSystemMappingFileUploadUrl(workflowType, fileUploadName);

        const response = await fetch(uploadUrl, { method: "PUT", body: file });
        if (!response.ok) {
            throw new ApiError(response.status);
        }
    }
}