import { InferenceMetricsDashboardService } from "../../api/inferences/inferenceMetricsDashboardService";

export class InferenceMetricsDashboardViewModel {
    private readonly _inferenceMetricsDashboardService: InferenceMetricsDashboardService;

    constructor(inferenceMetricsDashboardService: InferenceMetricsDashboardService) {
        this._inferenceMetricsDashboardService = inferenceMetricsDashboardService;
    }

    public getMetricsDashboardUrl(): Promise<string> {
        return this._inferenceMetricsDashboardService.getDashboardUrl();
    }
}