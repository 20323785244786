import { Inference } from "../../api/inferences/inference";

export class InferenceItem {
    public readonly inferenceId: string;
    public readonly executionTime: Date;
    public readonly inferenceDate: string;
    public readonly inferenceType: string;
    public readonly inferenceStatus: string;
    public readonly auditStatus: string;
    public readonly isDisabled: boolean;

    constructor(inference: Inference) {
        this.inferenceId = inference.inferenceId;
        this.executionTime = new Date(inference.executionTime * 1000);
        this.inferenceDate = inference.inferenceDate;
        this.inferenceType = inference.inferenceType;
        this.inferenceStatus = inference.inferenceStatus;
        this.auditStatus = inference.auditStatus;
        this.isDisabled = inference.inferenceStatus.toUpperCase() !== "SUCCEEDED";
    }
}