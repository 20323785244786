import { InferenceService } from "../../api/inferences/inferenceService";
import { AuditFile, InferenceDetails } from "../../api/inferences/inferenceDetails";
import { InferenceResultsViewModel } from "./inferenceResults/inferenceResultsViewModel";
import { SystemType } from "../../common/systemType";
import { InferenceAuditViewModel } from "./inferenceAudit/inferenceAuditViewModel";
import { InferenceInputSampleViewModel } from "./inferenceInput/inferenceInputSampleViewModel";

export class InferenceViewModel {
    private readonly _systemType: SystemType;
    private readonly _inferenceService: InferenceService;

    constructor(systemType: SystemType, inferenceService: InferenceService) {
        this._systemType = systemType;
        this._inferenceService = inferenceService;
    }

    public getInference(inferenceId: string): Promise<InferenceDetails> {
        return this._inferenceService.getInferenceDetails(inferenceId);
    }

    public createInferenceResultsViewModel(inference: InferenceDetails): InferenceResultsViewModel {
        return new InferenceResultsViewModel(this._systemType, inference, this._inferenceService);
    }

    public createInferenceAuditFileViewModel(inferenceId: string, auditFiles: AuditFile[]): InferenceAuditViewModel {
        return new InferenceAuditViewModel(inferenceId, auditFiles, this._inferenceService);
    }

    public createInferenceInputSampleFileViewModel(inference: InferenceDetails): InferenceInputSampleViewModel {
        return new InferenceInputSampleViewModel(inference, this._inferenceService);
    }
}