import { ApiClient } from "../apiClient";
import { Inference } from "./inference";
import { InferenceDetails } from "./inferenceDetails";
import { WorkflowType } from "../../common/workflowType";

export class InferenceService {
    private readonly _apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this._apiClient = apiClient;
    }

    public getInferences(): Promise<Inference[]> {
        return this._apiClient.get("/inferences");
    }

    public getInferenceDetails(inferenceId: string): Promise<InferenceDetails> {
        return this._apiClient.get(`/inferences/${inferenceId}`);
    }

    public getInferenceResultFileDownloadUrl(inferenceId: string, workflowType: WorkflowType, resultType: string): Promise<string> {
        return this._apiClient.get(`/inferences/${inferenceId}/download/${workflowType}/results/${resultType}`);
    }

    public getAuxiliaryFileDownloadUrl(inferenceId: string, workflowType: WorkflowType, fileName: string): Promise<string> {
        return this._apiClient.get(`/inferences/${inferenceId}/download/${workflowType}/auxiliary/${fileName}`);
    }

    public getSampleFileDownloadUrl(inferenceId: string, sampleName: string): Promise<string> {
        return this._apiClient.get(`/inferences/${inferenceId}/download/samples/${sampleName}`);
    }

    public getInferenceAuditFileDownloadUrl(inferenceId: string, auditFileName: string): Promise<string> {
        return this._apiClient.get(`/inferences/${inferenceId}/download/audit/${auditFileName}`);
    }

    public getInferenceInputSampleFileDownloadUrl(inferenceId: string): Promise<string> {
        return this._apiClient.get(`inferences/${inferenceId}/download/input/sample`);
    }

    public getInferenceAuditFileUploadUrl(inferenceId: string, auditFileName: string): Promise<string> {
        return this._apiClient.get(`/inferences/${inferenceId}/upload/audit/${auditFileName}`);
    }

    public getAdHocInferenceAsinsListUploadUrl(inferenceType: string, inferenceDate: string, asinsListFileName: string): Promise<string> {
        return this._apiClient.get(`/inferences/adhoc/${inferenceDate}/${inferenceType}/upload/${asinsListFileName}`);
    }

    public launchAdHocInference(inferenceType: string, inferenceDate: string): Promise<void> {
        return this._apiClient.post(`/inferences/adhoc/${inferenceDate}/${inferenceType}/run`);
    }

}