import "./notification.scss";
import { Alert } from "@amzn/awsui-components-react";
import { Component } from "react";
import { NotificationProps } from "./notificationProps";
import { NotificationState } from "./notificationState";
import { eventBus } from "../../../common/events/eventBus";

export class Notification extends Component<NotificationProps, NotificationState> {
    private unsubscribeGlobalErrors: (() => void) | undefined;

    constructor(props: NotificationProps) {
        super(props);
        this.state = { message: "" };
    }

    componentDidMount() {
        this.unsubscribeGlobalErrors = eventBus.subscribe("GLOBAL.*",
                event => this.setState({ message: event.payload.message }));
    }

    componentWillUnmount() {
        this.unsubscribeGlobalErrors?.();
    }

    render() {
        if (!this.state.message) {
            return (
                <div></div>
            );
        }

        return (
            <Alert
                className="notification"
                onDismiss={() => this.setState({ message: "" })}
                dismissAriaLabel="Close alert"
                dismissible
                type="error"
            >
                {this.state.message}
            </Alert>
        );
    }
}