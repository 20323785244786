import "@amzn/awsui-global-styles/polaris.css";

import { createRoot } from "react-dom/client";
import { AppViewModel } from "./app/appViewModel";
import { AuthProvider } from "oidc-react";
import { App } from "./app/app";
import { AuthProviderProps } from "oidc-react/build/src/AuthContextInterface";

document.addEventListener("DOMContentLoaded", () => {
    const appViewModel = new AppViewModel();

    appViewModel.initialize().then(() => {
        const authProviderProps: AuthProviderProps = {
            userManager: appViewModel.userManager,
            autoSignIn: true,
            onSignIn: () => {
                // Remove artifacts of PKCE flow
                window.history.replaceState({}, document.title, window.location.href.split("?")[0]);
            }
        };

        const appProps = {
            appViewModel: appViewModel
        };

        createRoot(createAppContainer()).render(
            <AuthProvider {...authProviderProps}>
                <App {...appProps}/>
            </AuthProvider>
        );
    });
});

function createAppContainer(): Element {
    const body = document.querySelector("body")!;
    const container = document.createElement("div");

    body.appendChild(container);

    return container;
}