import {TrainingDataService} from "../../../api/training/trainingDataService";
import { WorkflowType } from "../../../common/workflowType";
import {TrainingDataFile} from "../../../api/training/trainingDataSettings";

export class TrainingDataFileViewModel {
    private readonly _trainingDataService: TrainingDataService;
    private readonly _workflowType: WorkflowType;
    private readonly _trainingDataFile: TrainingDataFile;

    constructor(
        trainingDataService: TrainingDataService,
        workflowType: WorkflowType,
        trainingDataFile: TrainingDataFile) {
        this._trainingDataService = trainingDataService;
        this._workflowType = workflowType;
        this._trainingDataFile = trainingDataFile;
    }

    public getFile(): TrainingDataFile {
        return this._trainingDataFile;
    }

    public getFileDownloadUrl(): Promise<string> {
        return this._trainingDataService.getTrainingDataFileDownloadUrl(this._workflowType,
            this._trainingDataFile.downloadName);
    }

}