import { SystemSettingsService } from "../../../api/system/systemSettingsService";
import { WorkflowType } from "../../../common/workflowType";
import { MappingFile } from "../../../api/system/systemMappingSettings";

export class MappingFileViewModel {
    private readonly _systemSettingsService: SystemSettingsService;
    private readonly _workflowType: WorkflowType;
    private readonly _mappingFile: MappingFile;

    constructor(
        systemSettingsService: SystemSettingsService,
        workflowType: WorkflowType,
        mappingFile: MappingFile) {
        this._systemSettingsService = systemSettingsService;
        this._workflowType = workflowType;
        this._mappingFile = mappingFile;
    }

    public getFile(): MappingFile {
        return this._mappingFile;
    }

    public getFileDownloadUrl(): Promise<string> {
        return this._systemSettingsService.getSystemMappingFileDownloadUrl(this._workflowType, this._mappingFile.downloadName);
    }
}