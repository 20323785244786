import {TrainingMetricsDashboardService} from "../../api/training/trainingMetricsDashboardService";
import {TrainingMetricsSettings} from "../../api/training/trainingMetricsSettings";

export class TrainingMetricsDashboardSettingsViewModel {
    private readonly _trainingMetricsDashboardService: TrainingMetricsDashboardService;

    constructor(trainingMetricsDashboardService: TrainingMetricsDashboardService) {
        this._trainingMetricsDashboardService = trainingMetricsDashboardService;
    }

    public getTrainingMetricsDashboardSettings(): Promise<TrainingMetricsSettings> {
        return this._trainingMetricsDashboardService.getDashboardsUrls();
    }

}