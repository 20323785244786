import { MappingFilesUploaderProps } from "./mappingFilesUploaderProps";
import { Alert, Box, Button, Grid, Modal, SpaceBetween } from "@amzn/awsui-components-react";
import { useState } from "react";
import { FileUpload } from "../../common/fileUpload/fileUpload";
import { MappingFileUploaderResultState } from "./mappingFileUploaderResultState";

export function MappingFilesUploader(props: MappingFilesUploaderProps): JSX.Element {
    const mappingFilesUploaderViewModel = props.mappingFilesUploaderViewModel;
    const [isFilesUploadDialogVisible, setFilesUploadDialogVisibility] = useState(false);
    const [isUploadButtonEnabled, setIsUploadButtonEnabled] = useState(mappingFilesUploaderViewModel.isValid);
    const [isUploadInProgress, setIsUploadInProgress] = useState(false);
    const [successState, setSuccessState] = useState<MappingFileUploaderResultState>({ visible: false });

    function closeFilesUploadDialogWithSuccess(successMessage: string) {
        setIsUploadInProgress(false);
        setFilesUploadDialogVisibility(false);
        setSuccessState({ visible: true, message: successMessage });
    }

    function closeFilesUploadDialogWithError() {
        setIsUploadInProgress(false);
        setFilesUploadDialogVisibility(false);
    }

    return (
        <div>
            <Modal
                onDismiss={() => setFilesUploadDialogVisibility(false)}
                visible={isFilesUploadDialogVisible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setFilesUploadDialogVisibility(false)}>Cancel</Button>
                            <Button variant="primary"
                                    disabled={!isUploadButtonEnabled}
                                    loading={isUploadInProgress}
                                    onClick={() => {
                                        setIsUploadInProgress(true);
                                        mappingFilesUploaderViewModel.uploadMappingFiles()
                                            .then(successResult => closeFilesUploadDialogWithSuccess(successResult))
                                            .catch(_ => closeFilesUploadDialogWithError())
                                    }}>
                                Upload
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Upload New Files"
            >
                <Grid
                    gridDefinition={[
                        {colspan: 12},
                        {colspan: 12}
                    ]}
                >
                    <FileUpload
                        label="Choose Label Definitions File"
                        accept=".json"
                        displaySelectedFileName={true}
                        fileUploadHandler={
                            file => {
                                mappingFilesUploaderViewModel.handleLabelDefinitionsFileUpload(file);
                                setIsUploadButtonEnabled(mappingFilesUploaderViewModel.isValid);
                            }
                        }
                    />

                    <FileUpload
                        label="Choose Mapping Rules File"
                        accept=".csv"
                        displaySelectedFileName={true}
                        fileUploadHandler={
                            file => {
                                mappingFilesUploaderViewModel.handleMappingRulesFileUpload(file);
                                setIsUploadButtonEnabled(mappingFilesUploaderViewModel.isValid);
                            }
                        }
                    />
                </Grid>
            </Modal>

            <Grid
                gridDefinition={[
                    {colspan: 12},
                    {colspan: 12}
                ]}
            >
                <Button iconName="upload" variant="primary" onClick={() => setFilesUploadDialogVisibility(true)}>
                    Upload New Files
                </Button>

                <div>
                    <Alert
                        onDismiss={() => setSuccessState({ visible: false })}
                        visible={successState.visible}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type="success"
                    >
                        {successState.message}
                    </Alert>
                </div>
            </Grid>
        </div>
    );
}