import { InferenceInputSampleProps } from "./inferenceInputSampleProps";
import { Tabs } from "@amzn/awsui-components-react";
import { FileDownloadList } from "../../common/fileDownloadList/fileDownloadList";

export function InferenceInputSample(inferenceInputSampleProps: InferenceInputSampleProps): JSX.Element {
    const inferenceInputSampleViewModel = inferenceInputSampleProps.inferenceInputSampleViewModel;

    const tabs = [
        {
            label: "Input Data",
            id: "input",
            content: (
                <FileDownloadList files={[{
                    displayName: "Sample of Input ASINs",
                    downloadName: "sample_input.csv",
                    getFileDownloadUrl: () => inferenceInputSampleViewModel.getInferenceInputSampleFileDownloadUrl()
                }]}/>
            )
        }
    ]

    return (
        <Tabs
            tabs={tabs}
            variant="container"
        />
    );
}