import { Tabs } from "@amzn/awsui-components-react";
import { FileDownloadList } from "../../common/fileDownloadList/fileDownloadList";
import { WorkflowType } from "../../../common/workflowType";
import { InferenceResultsProps } from "./inferenceResultsProps";
import { SystemType } from "../../../common/systemType";

export function InferenceResults(inferenceResultsProps: InferenceResultsProps): JSX.Element {
    const inferenceResultsViewModel = inferenceResultsProps.inferenceResultsViewModel;

    const batteryDownloadFiles = [
        {
            displayName: "All ASINs",
            downloadName: "all_battery_asins.csv",
            getFileDownloadUrl: () => inferenceResultsViewModel.getInferenceResultFileDownloadUrl(WorkflowType.BATTERY, "all")
        },
        {
            displayName: "Unmapped ASINs",
            downloadName: "unmapped_battery_asins.csv",
            getFileDownloadUrl: () => inferenceResultsViewModel.getInferenceResultFileDownloadUrl(WorkflowType.BATTERY, "unmapped")
        }
    ];

    const weeeDownloadFiles = [
        {
            displayName: "All ASINs",
            downloadName: "all_weee_asins.csv",
            getFileDownloadUrl: () => inferenceResultsViewModel.getInferenceResultFileDownloadUrl(WorkflowType.WEEE, "all")
        },
        {
            displayName: "Unmapped ASINs",
            downloadName: "unmapped_weee_asins.csv",
            getFileDownloadUrl: () => inferenceResultsViewModel.getInferenceResultFileDownloadUrl(WorkflowType.WEEE, "unmapped")
        },
    ];

    if (inferenceResultsViewModel.showAuxiliaryFileDownloadUrl()) {
        batteryDownloadFiles.push({
                displayName: "Label Definitions",
                downloadName: "battery_label_definitions.json",
                getFileDownloadUrl: () => inferenceResultsViewModel.getAuxiliaryFileDownloadUrl(WorkflowType.BATTERY, "label_definition.json")
            },
            {
                displayName: "Mapping Rules",
                downloadName: "battery_mapping_rules.csv",
                getFileDownloadUrl: () => inferenceResultsViewModel.getAuxiliaryFileDownloadUrl(WorkflowType.BATTERY, "mapping_rules.csv")
            }
        );
        weeeDownloadFiles.push(
            {
                displayName: "Label Definitions",
                downloadName: "weee_label_definitions.json",
                getFileDownloadUrl: () => inferenceResultsViewModel.getAuxiliaryFileDownloadUrl(WorkflowType.WEEE, "label_definition.json")
            },
            {
                displayName: "Mapping Rules",
                downloadName: "weee_mapping_rules.csv",
                getFileDownloadUrl: () => inferenceResultsViewModel.getAuxiliaryFileDownloadUrl(WorkflowType.WEEE, "mapping_rules.csv")
            }
        );
    }

    const tabs = [
        {
            label: "Battery Classification Results",
            id: "battery",
            content: (
                <FileDownloadList files={batteryDownloadFiles}/>
            )
        },
        {
            label: "Weee Classification Results",
            id: "weee",
            content: (
                <FileDownloadList files={weeeDownloadFiles}/>
            )
        }
    ];

    if (inferenceResultsViewModel.systemType == SystemType.EPR_RETAIL) {
        tabs.push({
            label: "Battery Count Classification Results",
            id: "battery_count",
            content: (
                <FileDownloadList files={[
                    {
                        displayName: "All ASINs",
                        downloadName: "all_battery_count_asins.csv",
                        getFileDownloadUrl: () => inferenceResultsViewModel.getInferenceResultFileDownloadUrl(WorkflowType.BATTERY_COUNT, "all")
                    }
                ]}/>
            )
        });
    }

    const samples = [];
    for (const sample of inferenceResultsViewModel.inference.samples) {
        samples.push({
            displayName: sample.displayName,
            downloadName: sample.downloadName,
            getFileDownloadUrl: () => inferenceResultsViewModel.getSampleFileDownloadUrl(sample.downloadName)
        });
    }
    if (inferenceResultsViewModel.inference.isAuditAvailable) {
        tabs.push({
            label: "Sampled Results",
            id: "samples",
            content: (
                <FileDownloadList files={samples}/>
            )
        });
    }

    return (
        <Tabs
            tabs={tabs}
            variant="container"
        />
    );
}