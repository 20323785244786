import { InferenceAuditFileProps } from "./inferenceAuditFileProps";
import { Grid, Spinner } from "@amzn/awsui-components-react";
import { FileUpload } from "../../../common/fileUpload/fileUpload";
import { FileDownload } from "../../../common/fileDownload/fileDownload";
import { DateFormatter } from "../../../../common/dateFormatter";
import { useState } from "react";

export function InferenceAuditFile(props: InferenceAuditFileProps): JSX.Element {

    const [uploadIsInProgress, setUploadIsInProgress] = useState(false);
    const [updatedDate, setUpdatedDate] = useState(props.updatedDate)

    const uploadFile = function (file: File) {
        setUploadIsInProgress(true);
        return props.uploadHandler(file)
            .then(() => setUpdatedDate(new Date().getTime() / 1000))
            .finally(() => setUploadIsInProgress(false));
    }

    const buildDownloadElement = function (): JSX.Element {
        return (
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
                <FileDownload
                    displayName={props.label}
                    downloadName={props.fileName}
                    getFileDownloadUrl={() => props.downloadHandler()}
                />
                <label>updated {DateFormatter.formatDateTime(updatedDate)}</label>
            </Grid>
        );
    }

    const buildUploadElement = function (): JSX.Element {
        return (
            <FileUpload
                label="Upload"
                accept=".csv"
                displaySelectedFileName={false}
                fileUploadHandler={file => uploadFile(file)}
            />
        );
    }

    return (
        <Grid key={props.fileName} gridDefinition={[{ colspan: {'xl': 1 }}, { colspan: {'xl': 1} }, { colspan: 10 }]}>
            {buildUploadElement()}
            {uploadIsInProgress ? <Spinner size="big"></Spinner> : <div></div>}
            {updatedDate ? buildDownloadElement() : <label>{props.label}</label>}
        </Grid>
    );
}