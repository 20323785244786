import { ApiClient } from "../apiClient";

export class InferenceMetricsDashboardService {
    private readonly _apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this._apiClient = apiClient;
    }

    public getDashboardUrl(): Promise<string> {
        return this._apiClient.get("/inferences/metrics-dashboard");
    }
}