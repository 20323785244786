import { ApiClient } from "../apiClient";
import { SystemMappingSettings } from "./systemMappingSettings";
import { WorkflowType } from "../../common/workflowType";

export class SystemSettingsService {
    private readonly _apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this._apiClient = apiClient;
    }

    public getSystemMappingSettings() : Promise<SystemMappingSettings> {
        return this._apiClient.get("/system/mapping");
    }

    public getSystemMappingFileDownloadUrl(workflowType: WorkflowType, fileName: string) : Promise<string> {
        return this._apiClient.get(`/system/mapping/download/${workflowType}/${fileName}`);
    }

    public getSystemMappingFileUploadUrl(workflowType: WorkflowType, fileName: string): Promise<string> {
        return this._apiClient.get(`/system/mapping/upload/${workflowType}/${fileName}`);
    }
}