import {TrainingDataService} from "../../api/training/trainingDataService";
import {TrainingDataSettings, WorkflowTrainingDataSettings} from "../../api/training/trainingDataSettings";
import {WorkflowTrainingDataSettingsViewModel} from "./workflowTrainingSettings/workflowTrainingDataSettingsViewModel";
import {WorkflowType} from "../../common/workflowType";
import {SystemType} from "../../common/systemType";

export class TrainingDataSettingsViewModel {
    private readonly _trainingDataService: TrainingDataService;
    private readonly _systemType: SystemType;

    constructor(systemType: SystemType,
                trainingDataService: TrainingDataService) {
        this._trainingDataService = trainingDataService;
        this._systemType = systemType;
    }

    public getSystemType(): SystemType {
        return this._systemType;
    }

    public getTrainingDataSettings(): Promise<TrainingDataSettings> {
        return this._trainingDataService.getTrainingDataSettings();
    }

    public createWorkflowTrainingDataSettingsViewModel(
        workflowType: WorkflowType,
        workflowTrainingDataSettings: WorkflowTrainingDataSettings
    )
    {
            return new WorkflowTrainingDataSettingsViewModel(this._trainingDataService, workflowType, workflowTrainingDataSettings);
    }
}