import { MappingFileProps } from "./mappingFileProps";
import { Grid } from "@amzn/awsui-components-react";
import { FileDownload } from "../../common/fileDownload/fileDownload";
import { DateFormatter } from "../../../common/dateFormatter";

export function MappingFile(props: MappingFileProps): JSX.Element {
    const mappingFileViewModel = props.mappingFileViewModel;
    const file = mappingFileViewModel.getFile();

    return (
        <Grid gridDefinition={[{colspan: 2}, {colspan: 10}]}>
            <FileDownload displayName={file.displayName} downloadName={file.downloadName} getFileDownloadUrl={() => mappingFileViewModel.getFileDownloadUrl()}/>
            <label>updated {DateFormatter.formatDateTime(file.updateDate)}</label>
        </Grid>
    )
}