import "./inferenceList.scss"
import { Alert, Button, Cards, Grid, Link, Spinner } from "@amzn/awsui-components-react";
import { InferenceListProps } from "./inferenceListProps";
import { useEffect, useState } from "react";
import { InferenceListState } from "./inferenceListState";
import { AdHocInferenceLauncher } from "./adHocInferenceLauncher/adHocInferenceLauncher";
import { DateFormatter } from "../../common/dateFormatter";

export function InferenceList(props: InferenceListProps): JSX.Element {
    const inferenceListViewModel = props.inferenceListViewModel;
    const [state, setState] = useState<InferenceListState>({ loading: true, items: []});
    const [isInferenceLauncherVisible, setIsInferenceLauncherVisible] = useState(false);
    const [successAdHocLaunchMessage, setSuccessAdHocLaunchMessage] = useState("");

    useEffect(() => {
        let mounted = true;
        inferenceListViewModel.getInferences()
            .then(inferences => {
                if (mounted) {
                    setState({loading: false, items: inferences})
                }
            });
        return () => { mounted = false };
    }, []);

    if (state.loading) {
        return (
            <Spinner size="large" />
        );
    }

    return (
        <Cards
            header={
                (
                    <Grid gridDefinition={[{colspan: 12}, {colspan: 12}]} disableGutters={true}>
                        <div>
                            <Button iconName="add-plus" variant="primary" onClick={(_: any) => setIsInferenceLauncherVisible(true)}>
                                Launch Ad Hoc Inference
                            </Button>
                            <AdHocInferenceLauncher
                                isInferenceLauncherVisible={isInferenceLauncherVisible}
                                closeInferenceLauncher={() => setIsInferenceLauncherVisible(false)}
                                systemType={inferenceListViewModel.systemType}
                                launchAdhocInference={
                                    (inferenceType, inferenceDate, asinListFile) =>
                                        inferenceListViewModel
                                            .uploadAsinListFile(inferenceType, inferenceDate, asinListFile)
                                            .then(() => inferenceListViewModel.launchAdhocInference(inferenceType, inferenceDate))
                                            .then(() => setSuccessAdHocLaunchMessage("Ad hoc inference was successfully launched. It will appear in the list below after data queries are completed"))
                                }
                            />
                        </div>

                        <Alert className="success-adhoc-launch-message"
                            onDismiss={() => setSuccessAdHocLaunchMessage("")}
                            visible={!!successAdHocLaunchMessage}
                            dismissAriaLabel="Close alert"
                            dismissible
                            type="success"
                        >
                            {successAdHocLaunchMessage}
                        </Alert>
                    </Grid>
                )
            }
            cardDefinition={{
                header: item => {
                    if (item.isDisabled) {
                        return ("Inference");
                    }
                    return (<Link fontSize="heading-m" href={`#/inferences/${item.inferenceId}`}>Inference</Link>)
                },
                sections: [
                    {
                        id: "executionDate",
                        header: "Execution Date",
                        content: i => DateFormatter.formatDate(i.executionTime.getTime() / 1000)
                    },
                    {
                        id: "inferenceDate",
                        header: "Dataset Date",
                        content: i => i.inferenceDate
                    },
                    {
                        id: "inferenceType",
                        header: "Type",
                        content: i => i.inferenceType
                    },
                    {
                        id: "inferenceStatus",
                        header: "Inference Status",
                        content: i => i.inferenceStatus
                    },
                    {
                        id: "auditStatus",
                        header: "Audit Status",
                        content: i => i.auditStatus === "NOT_REQUIRED" ? <span className="hidden-audit-status"></span> : i.auditStatus
                    }
                ]
            }}
            items={state.items}
            trackBy="inferenceId"
            isItemDisabled={(item) => item.isDisabled}
        />
    );
}