import { Container, Header } from "@amzn/awsui-components-react";
import { InferenceAuditProps } from "./inferenceAuditProps";
import { InferenceAuditFile } from "./inferenceAuditFile/inferenceAuditFile";

export function InferenceAudit(inferenceAuditProps: InferenceAuditProps): JSX.Element {
    const inferenceAuditViewModel = inferenceAuditProps.inferenceAuditViewModel;

    const fileUploads = [];
    const files = [
        {
            fileName: "battery_positive_predictions_sample.csv",
            label: "Audited It Has Battery Sample",
        },
        {
            label: "Audited Is Weee Sample",
            fileName: "weee_positive_predictions_sample.csv",
        },
        {
            label: "Audited Model Sample",
            fileName: "model_sample.csv",
        },
        {
            label: "Audited Report Sample",
            fileName: "report_sample.csv",
        }
    ];

    for(const file of files) {
        fileUploads.push(
            <InferenceAuditFile
                key={file.fileName}
                fileName={file.fileName}
                label={file.label}
                updatedDate={inferenceAuditViewModel.getAuditFileUpdatedDate(file.fileName)}
                uploadHandler={(uploadFile) => inferenceAuditViewModel.uploadInferenceAuditFile(file.fileName, uploadFile)}
                downloadHandler={() => inferenceAuditViewModel.downloadInferenceAuditFile(file.fileName)}
            />
        );
    }
    return (
        <Container header={<Header variant="h3">Audit</Header>}>
            {fileUploads}
        </Container>
    );
}