export class DateFormatter {
     public static formatDateTime(timestampSec: number): string {
        const date = new Date(timestampSec * 1000);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1 < 10 ? "0" + (date.getUTCMonth() + 1) : (date.getUTCMonth() + 1);
        const day = date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
        const hours = date.getUTCHours() < 10 ? "0" + date.getUTCHours() : date.getUTCHours();
        const minutes = date.getUTCMinutes() < 10 ? "0" + date.getUTCMinutes() : date.getUTCMinutes();
        const seconds = date.getUTCSeconds() < 10 ? "0" + date.getUTCSeconds() : date.getUTCSeconds();
        return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds + " UTC";
    }

    public static formatDate(timestampSec: number): string {
        const date = new Date(timestampSec * 1000);
        return date.toISOString().split("T")[0];
    }
}