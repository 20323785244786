import { SystemSettingsService } from "../../api/system/systemSettingsService";
import { SystemMappingSettings, WorkflowMappingSettings } from "../../api/system/systemMappingSettings";
import { WorkflowMappingSettingsViewModel } from "./workflowMappingSettings/workflowMappingSettingsViewModel";
import { WorkflowType } from "../../common/workflowType";

export class SystemMappingSettingsViewModel {
    private readonly _systemSettingsService: SystemSettingsService;

    constructor(systemSettingsService: SystemSettingsService) {
        this._systemSettingsService = systemSettingsService;
    }

    public getMappingSettings(): Promise<SystemMappingSettings> {
        return this._systemSettingsService.getSystemMappingSettings();
    }

    public createWorkflowMappingSettingsViewModel(
        workflowType: WorkflowType,
        workflowMappingSettings: WorkflowMappingSettings): WorkflowMappingSettingsViewModel {
        return new WorkflowMappingSettingsViewModel(this._systemSettingsService, workflowType, workflowMappingSettings);
    }
}