import "./header.scss";

import { Header } from "@amzn/awsui-components-react";
import { AppHeaderProps } from "./headerProps";

export function AppHeader(props: AppHeaderProps): JSX.Element {
    const login = props.userEmail.split("@")[0];
    const fullName = `${props.userGivenName} ${props.userFamilyName}`;

    return (
        <Header
            id="header"
            className="header"
            actions={
                <div className="user-avatar-container">
                <img
                    className="user-avatar"
                    src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${login}`}
                    alt={fullName}
                    aria-label={fullName}/>
                </div>
            }
        >
            <span className="header-title">{props.title}</span>
        </Header>
    );
}